exports.components = {
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-main-js": () => import("./../../../src/templates/blogMain.js" /* webpackChunkName: "component---src-templates-blog-main-js" */),
  "component---src-templates-check-device-js": () => import("./../../../src/templates/check-device.js" /* webpackChunkName: "component---src-templates-check-device-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-solution-js": () => import("./../../../src/templates/solution.js" /* webpackChunkName: "component---src-templates-solution-js" */),
  "component---src-templates-tmtv-js": () => import("./../../../src/templates/tmtv.js" /* webpackChunkName: "component---src-templates-tmtv-js" */)
}

